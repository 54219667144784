import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { OneOffAuthContext } from '../oneOffAuth/OneOffAuthContext';
import { useLocation } from 'react-router-dom';

const withAuthorisation =
  ({ forwardUrl = '/', isLoginPage = false, registeredOnly = true }) =>
  (ComposedComponent) =>
  (props) => {
    const location = useLocation();
    const { isAuthenticated, handleLogin, isOneOffUser } =
      useContext(AuthContext);
    const { isOneOffAuthenticated } = useContext(OneOffAuthContext);

    if (isLoginPage && !isAuthenticated()) {
      return <ComposedComponent {...props} />;
    }
    if (isLoginPage && isAuthenticated()) {
      props.history.push(forwardUrl);
      return <></>;
    }

    if (isAuthenticated() && !isOneOffUser()) {
      return <ComposedComponent {...props} />;
    } else if ((isOneOffAuthenticated() || isOneOffUser()) && !registeredOnly) {
      return <ComposedComponent {...props} />;
    } else if ((isOneOffAuthenticated() || isOneOffUser()) && registeredOnly) {
      props.history.push('/unauthorised');
      return <></>;
    } else if (!isAuthenticated()) {
      handleLogin(location.pathname);
      return <></>; // Return empty tags as forwarding to key cloak server to handle login
    }
  };

export default withAuthorisation;
