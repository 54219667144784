/*eslint no-irregular-whitespace: ["error", { "skipJSXText": true }]*/
import React, { useEffect } from 'react';
import { StartButton } from '../../components/Govuk';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Home = () => {
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Report Suspicious Chemical Activity - Home Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);

  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        data-testid="content"
        tabIndex={0}
      >
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l" data-test="homepage-header">
            Upload regular reports
          </h1>
          <p className="govuk-body" data-test="header-sub-text">
            If you do not use an API (Application Programming Interface) to
            upload regular reports, use the button below to manually upload your
            report
          </p>
          <StartButton
            url="/upload"
            dataTest="start-upload-button"
            content="Upload report"
            style={{ borderRadius: '50%' }}
          />
          <div className="govuk-body">
            <ul className="govuk-list govuk-list--bullet">
              <li>
                To upload test data, go to{' '}
                <a
                  className="govuk-notification-banner__link"
                  href="https://crs.portal-preprod.dsa.homeoffice.gov.uk/login"
                >
                  test site
                </a>
                .
              </li>
              <li>
                {' '}
                <a
                  href="previous-uploads"
                  className="govuk-link"
                  data-test={`Previously uploaded reports-link`}
                >
                  View your previously uploaded reports
                </a>
                .
              </li>
            </ul>
          </div>
          <h2 className="govuk-heading-l" data-test="one-off-sar-header">
            One-off reporting
          </h2>
          <div className="govuk-body" data-test="one-off-sar-body">
            <p>
              <a
                href="sar/before-starting"
                className="govuk-link"
                data-test="one-off-sar-link"
              >
                Use the one-off reporting portal
              </a>{' '}
              to submit a report if you:
            </p>

            <ul className="govuk-list govuk-list--bullet">
              <li>
                have an urgent 'high risk' report that should be reported sooner
                than your routine submissions
              </li>
              <li> are experiencing issues with the portal</li>
            </ul>
          </div>
          <h2
            className="govuk-heading-l"
            data-test="aditional-information-header"
          >
            Additional information and guidance
          </h2>
          <h2
            className="govuk-heading-s"
            data-test={`API documentation-header`}
          >
            API reporting
          </h2>
          <p className="govuk-body" data-test={`API documentation-body`}>
            <a
              href="./docs/Guidance_for_API_reporting.pdf"
              className="govuk-link"
              download
              data-test={`API documentation-link`}
            >
              Technical guidance and reporting templates
            </a>{' '}
            are available for API reporters.
          </p>
          <h2 className="govuk-heading-s" data-test={`Precursor list-header`}>
            Explosive precursors and poisons
          </h2>
          <p className="govuk-body" data-test={`Precursor list-body`}>
            You can read the{' '}
            <a
              href="https://www.gov.uk/government/publications/supplying-explosives-precursors/supplying-explosives-precursors-and-poison"
              target="_blank"
              className="govuk-link"
              data-test={`Precursor list-link`}
            >
              latest gov.uk guidance on regulated and reportable substances
            </a>
            ,which also includes the full chemical list.
          </p>
          <h2 className="govuk-heading-l">Need help?</h2>
          <p className="govuk-body">
            If you have any questions or need help, email{' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            <br />
            This inbox is monitored by the team Monday to Friday 9am to 5pm. It
            is not monitored on bank holidays.
          </p>
        </div>
      </main>
    </>
  );
};

const HomeWithAuth = withAuthorisation({ forwardUrl: 'home' })(Home);
export { HomeWithAuth as Home };
