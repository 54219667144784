import React, { lazy, useState, useEffect, Suspense } from 'react';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { getPrecursorList, getProfile } from './api';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const PrecursorMessage = lazy(() =>
  import('./components/PrecursorMessage').then(({ PrecursorMessage }) => ({
    default: PrecursorMessage,
  }))
);

const PrecursorList = lazy(() =>
  import('./components/PrecursorList').then(({ PrecursorList }) => ({
    default: PrecursorList,
  }))
);

const PrecursorListPage = () => {
  const [precursorList, setPrecursorList] = useState(null);
  const [currentRetailer, setCurrentRetailer] = useState(null);
  const [noPrecursorList, setNoPrecursorList] = useState(null);
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    getPrecursorList().then((res) => {
      if (res.data && res.data.length > 0) {
        setPrecursorList(res.data);
      } else {
        setNoPrecursorList(true);
      }
      trackPageView();
      trackEvent({
        category: 'Precursor List',
        action: 'Visited',
      });
    });

    getProfile('retailer').then((res) => {
      setCurrentRetailer(res.data);
    });
  }, []);

  return (
    <main
      style={{}}
      className="govuk-main-wrapper"
      id="content"
      role="main"
      tabIndex={0}
    >
      <h1 className="govuk-panel__title" data-test="precursor-list-title">
        Precursor List
      </h1>
      {noPrecursorList ? (
        <Suspense fallback={<div>Loading...</div>}>
          <PrecursorMessage />
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <PrecursorList
            precursorList={precursorList}
            currentRetailer={currentRetailer}
          />
        </Suspense>
      )}
    </main>
  );
};

const PrecursorListWithAuth = withAuthorisation({
  forwardUrl: 'precursor-list',
})(PrecursorListPage);

export { PrecursorListWithAuth as PrecursorListPage };
