import axios from 'axios';

export const addIdTokenToRequests = (keycloak) => {
  return axios.interceptors.request.use((request) => {
    if (keycloak.idToken) {
      request.headers.Authorization = keycloak.idToken;
    }
    return request;
  });
};

const displayErrorPage = (props) => {
  if (props.location.pathname === '/sar-registration') {
    props.history.push('/one-off-sars-error');
  } else if (props.location.pathname.match(/^\/sar\//)) {
    props.history.push('/error/oneoff');
  } else if (props.location.pathname === '/upload') {
    props.history.push('/sar-error');
  } else {
    props.history.push('/error');
  }
};

export const httpErrorHandler = (props) => {
  return axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 500:
            displayErrorPage(props);
            break;
          case 401:
            props.history.push('/login');
            break;
          case 404:
            props.history.push('/not-found');
            break;
          case !415:
            props.history.push('/something-went-wrong');
            break;
        }
      } else {
        displayErrorPage(props);
      }
      return Promise.reject(error);
    }
  );
};
