import React, { useContext } from 'react';
import { AuthContext } from '../../components/auth/AuthContext';

export const RegularReporting = () => {
  const { handleLogin } = useContext(AuthContext);

  const login = (e) => {
    e.preventDefault();
    handleLogin('home');
  };

  return (
    <>
      <h2 className="govuk-heading-l" data-test="regular-reporting-header">
        Regular reporting
      </h2>
      <p className="govuk-body" data-test="regular-reporting-body">
        Use this service to upload data if you are already registered with the
        service.
      </p>
      <p className="govuk-body">
        {' '}
        <a
          href="#"
          className="govuk-link"
          onClick={login}
          data-test="regular-reporting-link"
        >
          Registered users - sign in
        </a>
      </p>
      <br />
      <br />
      <br />
    </>
  );
};
