import React from 'react';
import { Link } from 'react-router-dom';

export const OneOffSarRegistrationNotAllowed = () => {
  return (
    <div className="govuk-width-container">
      <main
        className="govuk-main-wrapper "
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">Operation Not Allowed</h1>
            <p className="govuk-body">
              As a retailer you do not need to register again to submit a
              One-Off SAR, you can do this by logging in to your account and
              using the link provided.
            </p>
            <Link to={'/login'}>Return to the home page</Link>
          </div>
        </div>
      </main>
    </div>
  );
};
