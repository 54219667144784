import React from 'react';
import withAuthorisation from '../../components/auth/WithAuthorisation';

const Success = (props) => {
  return (
    <main className="govuk-main-wrapper govuk-main-wrapper--l">
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <div className="govuk-panel govuk-panel--confirmation">
            <h1 className="govuk-panel__title">Data upload complete</h1>
            <div className="govuk-panel__body">
              <p>Your Suspicious Activity Report ID</p>
              <strong id="data-uuid" data-test="success-uuid">
                {props.uuid}
              </strong>
            </div>
          </div>
          <p
            className="govuk-body"
            data-test="success-body"
            style={{ fontSize: '23px' }}
          >
            You can check the status of your data submission using the ID given
            above in{' '}
            <a
              href="previous-uploads"
              className="govuk-link"
              data-test="prev-upload-link"
            >
              previously uploaded reports
            </a>
            .
          </p>
          <p className="govuk-body" data-test="contact-email-address">
            If you have any questions or need support, contact the team at{' '}
            <a
              href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
              data-test="upload-contact-1-link"
            >
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
          </p>
          <a href="home" className="govuk-link">
            Return to the home page.
          </a>
        </div>
      </div>
    </main>
  );
};

const SuccessWithAuth = withAuthorisation({ forwardUrl: '/success' })(Success);
export { SuccessWithAuth as Success };
