import axios from 'axios';
import { getConfig } from '../../Config';

const sarsReportsEndpoint = getConfig().apiBaseUrl;

export const getPrecursorList = () => {
  return axios.get(`${sarsReportsEndpoint}ref-data/precursor-list`);
};

export const getProfile = (params) => {
  return axios.get(`${sarsReportsEndpoint}ref-data/profile`, {
    params: {
      claims: params,
    },
  });
};
