import React from 'react';
import withAuthorisation from '../../components/auth/WithAuthorisation';

import { RegularReporting } from './RegularReporting';
import { OneOffReport } from './OneOffReport';
import { Contact } from './Contact';

const Login = () => {
  return (
    <>
      <main className="govuk-main-wrapper" id="content" role="main">
        <div className="govuk-grid-column">
          <h1 className="govuk-heading-l" data-test="login-header">
            Report suspicious chemical activity
          </h1>
          <p className="govuk-body" data-test="login-main-text">
            You must submit a report if you suspect someone has bought or tried
            to buy chemicals to build explosives or cause harm, or if any
            chemicals have been lost or stolen.
            <br />
            <br />
            Use this service to:
            <br />
            <br />
            <ul className="govuk-list govuk-list--bullet">
              <li>
                make a one-off report as a retailer or a member of the public
              </li>
              <li>upload data as a registered user</li>
            </ul>
          </p>

          <RegularReporting />
          <OneOffReport />
          <Contact />
        </div>
      </main>
    </>
  );
};

const LoginWithAuth = withAuthorisation({
  forwardUrl: '/home',
  isLoginPage: true,
})(Login);
export { LoginWithAuth as Login };
