import React, { useState } from 'react';

export const DatePicker = (props) => {
  const { id, formClass } = props;

  const handleDayChange = (day) => {
    if (day < 32) {
      setDay(day);
      props.setDate('day', day);
    }
  };

  const handleMonthChange = (month) => {
    if (month < 13) {
      setMonth(month);
      props.setDate('month', month - 1); // Minus 1 due to 0 based month array
    }
  };

  const handleYearChange = (year) => {
    const now = new Date();
    if (year <= now.getFullYear()) {
      setYear(year);
      props.setDate('year', year);
    }
  };

  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();

  return (
    <>
      <div className={`${formClass}`}>
        <fieldset
          className="govuk-fieldset"
          role="group"
          aria-describedby={`${id}-hint`}
        >
          <h2 className="govuk-fieldset__heading govuk-heading-l">
            {props.title}
          </h2>
          <span
            style={{ fontSize: '15px' }}
            id={`${id}-hint`}
            className="govuk-hint"
          >
            For example, 01/01/2020
          </span>
          <div className="govuk-date-input" id={id}>
            <div className="govuk-date-input__item">
              <div className="govuk-form-group">
                <label
                  for={`${id}-day`}
                  className="govuk-label govuk-date-input__label"
                >
                  Day
                </label>
                <input
                  className="govuk-input govuk-date-input__input govuk-input--width-2"
                  id={`${id}-day`}
                  onChange={(e) => handleDayChange(e.target.value)}
                  name={`${id}-day`}
                  type="number"
                  pattern="[0-9]*"
                  data-test={`input-day-${props.title}`}
                  value={day}
                  max="31"
                  min="1"
                />
              </div>
            </div>
            <div className="govuk-date-input__item">
              <div className="govuk-form-group">
                <label
                  for={`${id}-month`}
                  className="govuk-label govuk-date-input__label"
                >
                  Month
                </label>
                <input
                  className="govuk-input govuk-date-input__input govuk-input--width-2"
                  id={`${id}-month`}
                  onChange={(e) => handleMonthChange(e.target.value)}
                  name={`${id}-month`}
                  type="number"
                  pattern="[0-9]*"
                  data-test={`input-month-${props.title}`}
                  value={month}
                  max="12"
                  min="1"
                />
              </div>
            </div>
            <div className="govuk-date-input__item">
              <div className="govuk-form-group">
                <label
                  for={`${id}-year`}
                  className="govuk-label govuk-date-input__label"
                >
                  Year
                </label>
                <input
                  className="govuk-input govuk-date-input__input govuk-input--width-4"
                  id={`${id}-year`}
                  onChange={(e) => handleYearChange(e.target.value)}
                  name={`${id}-year`}
                  type="number"
                  pattern="[0-9]*"
                  data-test={`input-year-${props.title}`}
                  value={year}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
