import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { BeforeStart } from './BeforeStart';
import { ProviderInformation, Organisation } from './ProviderInformation';
import { AboutIncident, Suspicion } from './AboutIncidentSection';
import {
  PersonKnown,
  OtherEvidence,
  BuyerInformation,
  VehicleInfo,
  TheftCrimeReference,
  TheftPersonDetails,
} from './AboutPersonSection';
import {
  ProductInformation,
  WeightOrItem,
  Weight,
  Item,
  Cost,
  IsDiscount,
  DiscountDetails,
  DiscountApplied,
  ProductReview,
  Unit,
  MultipleItems,
  Chemical,
  RemoveConfirm,
} from './ProductInformation';
import {
  Method,
  CustomerId,
  ThirdParty,
  ThirdPartyVendor,
  ThirdPartyRef,
  DateTime,
  DeliveryDetails,
  PaymentMethod,
  BankDetails,
  PaymentOther,
  PaymentServiceId,
  MoreInfo,
  Price,
} from './TransactionSection';
import { ReviewAnswers } from './ReviewAnswers/ReviewAnswers';
import { Uploaded } from './Uploaded';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { OneOffSarInitialValues } from './initial-values';

export const STORE_KEY = 'RSCAS_REPORTING';

const OneOffSars = (props) => {
  const storeData = fetchAnswersFromLocal();
  const [sarData, setSarData] = useState(storeData || OneOffSarInitialValues);
  const { location, history, match } = props;

  useEffect(() => {
    if (!storeData) {
      setSarData(OneOffSarInitialValues);
      setAnswersToLocal(OneOffSarInitialValues);
      history.push('/sar/before-starting');
    }
  }, [storeData]);

  useEffect(() => {
    setAnswersToLocal(sarData);
  }, [sarData]);

  const updateValues = (values, path) => {
    setSarData((sar) => {
      return { ...sar, ...values };
    });
    if (path) {
      history.push(`/sar/${path}`);
    }
  };

  const editSection = (path) => {
    setSarData((sar) => ({ ...sar, editMode: true }));
    history.push(`/sar/${path}`);
  };

  function fetchAnswersFromLocal() {
    const saved = JSON.parse(sessionStorage.getItem(STORE_KEY));
    if (saved) {
      saved.sections = new Set(saved.sections);
    }
    return saved;
  }

  function setAnswersToLocal(data) {
    const copy = Object.assign({}, data);
    copy.sections = Array.from(copy.sections);
    sessionStorage.setItem(STORE_KEY, JSON.stringify(copy));
  }

  useEffect(() => {
    document.querySelector('#skiplink-container').focus();
  }, [location.pathname]);

  return (
    <>
      <Switch>
        <Route path={match.url + '/before-starting'} component={BeforeStart} />
        <Route
          exact
          path={match.url + '/provider-information'}
          component={() => (
            <ProviderInformation
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/organisation'}
          component={() => (
            <Organisation initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/about-incident'}
          component={() => (
            <AboutIncident
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/suspicion'}
          component={() => (
            <Suspicion initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/about-person-details'}
          component={() => (
            <PersonKnown initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/other-information'}
          component={() => (
            <OtherEvidence
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/crime-reference'}
          component={() => (
            <TheftCrimeReference
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/suspicious-person'}
          component={() => (
            <TheftPersonDetails
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/vehicle-information'}
          component={() => (
            <VehicleInfo initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/buyer-information'}
          component={() => (
            <BuyerInformation
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/product-information'}
          component={() => (
            <ProductInformation
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/weight-or-item'}
          component={() => (
            <WeightOrItem initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/unit-details'}
          component={() => (
            <Unit initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/weight-details'}
          component={() => (
            <Weight initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/multiple-item-details'}
          component={() => (
            <MultipleItems
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/item-details'}
          component={() => (
            <Item initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/chemical'}
          component={() => (
            <Chemical initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/cost'}
          component={() => (
            <Cost initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/is-discount'}
          component={() => (
            <IsDiscount initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/discount-details'}
          component={() => (
            <DiscountDetails
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/discount-applied'}
          component={() => (
            <DiscountApplied
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/product-review'}
          component={() => (
            <ProductReview
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/product-remove'}
          component={() => (
            <RemoveConfirm
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/price'}
          component={() => (
            <Price initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/transaction-information'}
          component={() => (
            <Method initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/customer-id'}
          component={() => (
            <CustomerId initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/third-party'}
          component={() => (
            <ThirdParty initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/vendor-name'}
          component={() => (
            <ThirdPartyVendor
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/channel-ref'}
          component={() => (
            <ThirdPartyRef
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/transaction-date'}
          component={() => (
            <DateTime initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/delivery-details'}
          component={() => (
            <DeliveryDetails
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/payment-method'}
          component={() => (
            <PaymentMethod
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/payment-other'}
          component={() => (
            <PaymentOther initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/payment-service-id'}
          component={() => (
            <PaymentServiceId
              initialValues={sarData}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/bank-details'}
          component={() => (
            <BankDetails initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/more-info'}
          component={() => (
            <MoreInfo initialValues={sarData} updateValues={updateValues} />
          )}
        />
        <Route
          exact
          path={match.url + '/review-answers'}
          component={() => (
            <ReviewAnswers
              initialValues={sarData}
              editSection={editSection}
              updateValues={updateValues}
            />
          )}
        />
        <Route
          exact
          path={match.url + '/uploaded'}
          component={() => <Uploaded sarData={sarData} />}
        />
      </Switch>
    </>
  );
};

const OneOffSarsWithAuth = withAuthorisation({
  forwardUrl: 'sar/before-starting',
  registeredOnly: false,
})(OneOffSars);

export { OneOffSarsWithAuth as OneOffSars };
