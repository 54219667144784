import React, { useContext } from 'react';
import { OneOffAuthContext } from '../../components/oneOffAuth/OneOffAuthContext';

export const OneOffReport = () => {
  const { isOneOffAuthenticated } = useContext(OneOffAuthContext);

  return (
    <>
      <h2 className="govuk-heading-l" data-test="one-off-header">
        Send a one-off report
      </h2>
      <p className="govuk-body" data-test="one-off-body">
        Use this service if you are a retailer and you are not registered with
        the regular reporting service.
      </p>
      <p className="govuk-body">
        {' '}
        <a
          href={
            isOneOffAuthenticated() ? 'sar/before-starting' : 'sar-registration'
          }
          data-test="one-off-link"
        >
          Submit a one-off suspicious activity report
        </a>
      </p>
      <p>
        If you believe you should be registered please use the contact details
        below.
      </p>
      <br />
      <br />
      <br />
    </>
  );
};
