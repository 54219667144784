import React from 'react';
import './index.css';
import App from './App';
import AuthProvider from './components/auth/AuthContext';
import OneOffAuthProvider from './components/oneOffAuth/OneOffAuthContext';
import Keycloak from 'keycloak-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { addIdTokenToRequests } from './containers/Errors/HttpInterceptor';
import { handleTokenExpiredEvent } from './components/auth/TokenUtils';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { getConfig } from './Config';
import { createRoot } from 'react-dom/client';

const setUpApp = () => {
  const keycloak = Keycloak('/config/keycloak.json');
  const matomoSiteId = getConfig().matomoSiteId;
  const matomoUrl = getConfig().matomoUrl;

  const instance = createInstance(
    {
      urlBase: matomoUrl,
      siteId: matomoSiteId,
      trackerUrl: `${matomoUrl}/matomo.php`,
      srcUrl: `${matomoUrl}/matomo.js`,
      disabled: false,
      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    },
    [matomoSiteId, matomoUrl]
  );

  const renderApp = () => {
    keycloak
      .init({
        promiseType: 'native',
        flow: 'hybrid',
        checkLoginIframe: false,
      })
      .then(() => {
        const root = createRoot(document.getElementById('root'));
        root.render(
          <MatomoProvider value={instance}>
            <AuthProvider keycloak={keycloak}>
              <OneOffAuthProvider>
                <Router>
                  <App keycloak={keycloak} />
                </Router>
              </OneOffAuthProvider>
            </AuthProvider>
          </MatomoProvider>
        );
      });
  };

  handleTokenExpiredEvent(keycloak);
  addIdTokenToRequests(keycloak);
  renderApp();
};

setUpApp();
